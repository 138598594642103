<template>
  <aside id="statement-details-modal" class="modal-window">
    <header>
      <h2>Statement Details</h2>
      <a href="#" @click.prevent="$emit('close')" class="close"><fa-icon icon="times" /></a>
    </header>

    <div class="scroll-view" v-if="statement !== null">
      <el-alert type="warning" v-if="statement.statuses[0].status == 'PAID' && statement.fee_invoice_id === null" show-icon :closable="false" title="Your Donation Processing Fee on this statement is still outstanding. This typically occurs due to a payment issue." />
      <div class="title">
        <div>
          <h1>{{statement.title}} <small>Donation Statement</small></h1>
          <h2>{{statusText}}</h2>
        </div>
        <div class="pay-btn" v-if="statement.invoice">
          <a href="#" class="el-button el-button--success el-button--small is-round" @click.prevent="openPaymentModal" v-if="showPayBtn">{{payBtnText}}</a>
          <div v-if="!!statement.invoice.invoice_pdf">
            <a :href="statement.invoice.invoice_pdf" class="link" rel="noopener" target="_blank">Download PDF</a>
          </div>
        </div>
      </div>

      <div class="details">
        <div><strong>Total donations: </strong>{{$formatMoney(statement.total_amount)}}</div>
        <div><strong>Donation processing fee: </strong>{{$formatMoney(statement.donation_fee/100, '0,0.00')}}</div>
        <div><strong>Payment processing fee: </strong>{{$formatMoney(statement.payment_fee/100, '0,0.00')}}</div>
        <div><strong>Total: </strong>{{$formatMoney(statement.invoice_total/100, '0,0.00')}}</div>
      </div>

      <h4>Donations</h4>
      <ul class="celebrations">
        <li v-for="donation in statement.donations" :key="`donation_${donation.id}`">
          <div class="name">
            <profile-picture :user="donation.celebration.user" height="30" />
            <div class="title">
              <span>
                <name :user="donation.celebration.user" full />'s birthday
              </span>
              <small>
                {{donation.charity_details.name}}
              </small>
            </div>
          </div>
          <div class="timestamp">
            {{timestamp(donation.created_at)}}
          </div>
          <div class="amount">
            <span>{{$formatMoney(donation.currency_amount)}}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="scroll-view" v-else>
      <loading-indicator color="dark" />
    </div>
  </aside>
</template>

<script>
import {DateTime} from 'luxon'
export default {
  name: 'statement-details',
  computed: {
    statusText() {
      const duedate = DateTime.fromISO(this.statement.due_date);
      switch( this.statement.statuses[0].status ) {
        case 'PENDING':
          return 'Invoice being prepared...';
        break;
        case 'FINALIZED':
          return `Due on ${duedate.toLocaleString(DateTime.DATE_FULL)}`;
        break;
        case 'PAID':
          return 'Paid';
        break;
      }
    },
    showPayBtn() {
      if( this.statement.statuses[0].status == 'FINALIZED' ) return true;
      if( this.statement.statuses[0].status == 'PAID' && this.statement.fee_invoice_id === null ) return true;

      return false;
    },
    payBtnText() {
      if( this.statement.statuses[0].status == 'PAID' && this.statement.fee_invoice_id === null ) return `Pay remaining fee`;

      return `Pay now`;
    }
  },
  data() {
    return {
      statement: null
    }
  },
  mounted() {
    if( this.$route.params.data ) {
      this.statement = _.cloneDeep( this.$route.params.data )
    }
    else {
      this.populateStatement( this.$route.params.statementId )
    }
  },
  methods: {
    timestamp(time) {
      return DateTime.fromISO(time).toLocaleString( DateTime.DATE_MED )
    },
    async populateStatement(id) {
      const resp = await this.$api.Statements.get(id)

      this.statement = resp;
    },
    openPaymentModal() {
      this.$root.$emit('openDialog', () => import('@/components/Modals/Statements/PayStatement'), this.statement, (res) => {
        if( res === 'PAID' ) {
          this.statement.statuses.unshift({
            status: 'PAID'
          });
          this.statement.fee_invoice_id = 'asdfkl';
          //this.populateStatement(id);
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#statement-details-modal {
  .scroll-view {

    .el-alert {
      margin: 0 0 25px;
    }

    .details {
      margin: 0 -25px 25px 0;
      padding: 0 0 0 0;
      >div {
        padding: 7px 25px 7px 0;
        border-bottom: 1px solid #e7ded5;
        font-size: 14px;
        line-height: 16px;

        >strong {
          width: 200px;
          display: inline-block;
        }
      }
    }

    >.title {
      display: flex;
      margin: 0 0 25px;

      .pay-btn {
        margin: 0 0 0 auto;
        text-align: right;

        >span {
          display: block;
          font-size: 14px;
          margin: 0 0 5px;
        }
        > a {
          text-decoration: none;
        }

        .link {
          font-size: 14px;
          margin: 5px 0 0;
          display: inline-block;
          color: $red;
        }
      }
    }

    h1 {
      font-size: 24px;
      font-weight: 700;
      margin: 0 0 10px;

      > small {
        white-space: nowrap;
        font-size: 18px;
        font-weight: normal;
        color: #757575;
      }
    }

    h2 {
      font-size: 16px;
      color: #757575;
    }

    h4 {
      font-weight: 700;
    }

    .celebrations {
      padding: 0 0 0 0;
      margin: 0 -25px 0 0;
      > li {
        display: flex;
        align-items: center;
        padding: 10px 25px 10px 0;
        border-bottom: 1px solid #e7ded5;

        .timestamp {
          margin: 0 0 0 auto;
          font-size: 14px;
        }
        .amount {
          margin: 0 0 0 15px;

            > span {
              background: $green;
              border-radius: 100em;
              padding: 4px 10px;
              display: block;
              font-size: 14px;
            }
          }

        .name {
          display: flex;
          align-items: center;

          > .title {
            > span {
              display: block;
              font-weight: 700;
              font-size: 14px;
              line-height: 14px;
              margin: 0 0 3px;
            }
            > small {
              display: block;
              font-size: 12px;
              line-height: 12px;
            }
          }

          .profile-picture {
            margin: 0 10px 0 0;
          }
        }
      }
    }
  }
}
</style>